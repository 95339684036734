import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Container, Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaTrophy, FaRunning, FaChartBar } from 'react-icons/fa';

const Dashboard = () => {
  const [top10, setTop10] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTop10();
  }, []);

  const fetchTop10 = async () => {
    const token = localStorage.getItem('jwt');  
    const authHeader = { headers: { 'Authorization': `Bearer ${token}` } };
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mediciones/top10`, authHeader);
      setTop10(response.data);
    } catch (error) {
      console.error('Error al cargar el Top 10:', error);
    }
  };

  const agrupadosPorTipo = top10.reduce((acc, jugador) => {
    if (!acc[jugador.tipo]) acc[jugador.tipo] = [];
    acc[jugador.tipo].push(jugador);
    return acc;
  }, {});

  const mediciones = ['pulldown', 'exitvelo', 'bullpen_velocidad', '90 pies', 'BatSpeedMax', 'PeakHandSpeedAvg'];

  return (
    <Container className="py-4">
      <h1 className="text-center mb-4" style={{ fontWeight: 'bold', color: '#0056b3' }}>⚾ VELEZ BEISBOL</h1>

      {/* Jugadores destacados */}
      <Card className="mb-4 shadow-sm">
        <Card.Header className="d-flex align-items-center bg-primary text-white">
          <FaTrophy size={24} className="me-2" />
          <h5 className="m-0">Jugadores Destacados 2025</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead className="table-dark">
              <tr>
                <th>Fecha</th>
                <th>Jugador</th>
                <th>Categoria</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>16-02-2025</td>
                <td>Emilio Contreras</td>
                <td>A1</td>
              </tr>
              <tr>
                <td>23-02-2025</td>
                <td>Delfina Antoniassi</td>
                <td>Femenino</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Top Asistencias */}
      <Card className="mb-4 shadow-sm">
        <Card.Header className="d-flex align-items-center bg-success text-white">
          <FaRunning size={24} className="me-2" />
          <h5 className="m-0">Top 3 Asistencias (Últimos 3 meses)</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead className="table-dark">
              <tr>
                <th>Jugador</th>
                <th>Asistencias</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>Tobias Comoglio</td><td>42</td></tr>
              <tr><td>David Erusalimsky</td><td>40</td></tr>
              <tr><td>Carlos "Gogo" Chirinos</td><td>38</td></tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* TOP 10 Mediciones */}
      <Card className="mb-4 shadow-sm">
        <Card.Header className="d-flex justify-content-between align-items-center bg-danger text-white">
          <div className="d-flex align-items-center">
            <FaChartBar size={24} className="me-2" />
            <h5 className="m-0">TOP 10 Mediciones</h5>
          </div>
          <Button variant="light" onClick={() => navigate(`/ver-mas-mediciones`)}>
            Ver Más
          </Button>
        </Card.Header>

        <Card.Body>
          <Row>
            {mediciones.map((tipo) => (
              <Col md={4} key={tipo}>
                <Card className="mb-4 shadow-sm">
                  <Card.Header className="bg-info text-white">
                    <h6 className="m-0">{tipo.toUpperCase()}</h6>
                  </Card.Header>
                  <Card.Body>
                    <Table striped bordered hover>
                      <thead className="table-light">
                        <tr>
                          <th>Nombre</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agrupadosPorTipo[tipo]?.map((jugador, index) => (
                          <tr key={index} style={{ backgroundColor: jugador.genero === 'F' ? '#f8c8dc' : 'transparent' }}>
                            <td>{jugador.nombre} {jugador.apellido}</td>
                            <td>{jugador.valor}</td>
                          </tr>
                        )) || (
                          <tr>
                            <td colSpan="2" className="text-center">No hay datos</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Dashboard;
