// src/utils/campos.js
const campos = {
    1: { nombre: 'ENB1', direccion: 'https://flagcdn.com/w320/ar.png' },
    2: { nombre: 'ENB2', direccion: 'https://flagcdn.com/w320/ve.png' },
    3: { nombre: 'ENB3', direccion: 'https://flagcdn.com/w320/bo.png' },
    4: { nombre: 'Daom (Flores)', direccion: 'https://flagcdn.com/w320/tw.png' },
    5: { nombre: 'Jupiter (Ciudad Evita)', direccion: 'https://flagcdn.com/w320/cu.png' },
    6: { nombre: 'Pontevedra', direccion: 'https://flagcdn.com/w320/co.png' },
    7: { nombre: 'Escobar', direccion: 'https://flagcdn.com/w320/it.png' },
    8: { nombre: 'La Plata', direccion: 'https://flagcdn.com/w320/us.png' },
    9: { nombre: 'Parque Dorrego', direccion: 'https://flagcdn.com/w320/do.png' },
    10: { nombre: 'Lanus', direccion: 'https://flagcdn.com/w320/cu.png' },
    11: { nombre: 'Popeye (Salta)', direccion: 'https://flagcdn.com/w320/co.png' },
    12: { nombre: 'Cachorros (Salta)', direccion: 'https://flagcdn.com/w320/it.png' },
    13: { nombre: 'Santana (Salta)', direccion: 'https://flagcdn.com/w320/us.png' },
    14: { nombre: 'Dolphin (Cordoba)', direccion: 'https://flagcdn.com/w320/do.png' },
  };
  
  export default campos;
  
    